<template>
    <v-container>
        <v-card class="mx-auto my-12 pa-6" max-width="600">
            <v-card-title class="text-h5">No hay resultados</v-card-title>
            <v-card-text>
                No se encontraron resultados para su búsqueda. Por favor, intente con otros términos o vuelva más tarde.
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="primary" @click="$router.push('/')">Volver al inicio</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "NotFound",
};
</script>