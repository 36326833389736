import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ReportForm from "../views/ReportForm.vue";
import SearchFormPublic from "../views/SearchFormPublic.vue";
import SearchForm from "../views/SearchForm.vue";
import CaseDetail from "../views/CaseDetail.vue";
import CaseDetailPublic from "../views/CaseDetailPublic.vue";
import CaseDetailPrint from "../views/CaseDetailPrint.vue";
import ThankYou from "@/components/ThankYou.vue";
import NotFound from "@/components/NotFound.vue";
import UnauthorizedAccess from "@/components/NoAccess.vue";
import Login from "@/components/Login.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  { path: "/", name: "Home", component: HomeView },
  { path: "/denunciar", name: "ReportForm", component: ReportForm },
  {
    path: "/denunciar-publico",
    name: "SearchFormPublic",
    component: SearchFormPublic,
  },
  { path: "/buscar", name: "SearchForm", component: SearchForm },
  { path: "/congrats", name: "thank-you", component: ThankYou },
  { path: "/error", name: "NotFound", component: NotFound },
  {
    path: "/unauthorized",
    name: "UnauthorizedAccess",
    component: UnauthorizedAccess,
  },
  {
    path: "/detalle/:id",
    name: "CaseDetail",
    component: CaseDetail,
    props: true,
  },
  {
    path: "/detalle-publico/:uniqueId",
    name: "CaseDetailPublic",
    component: CaseDetailPublic,
    props: (route) => ({ initialUniqueId: route.params.uniqueId }), // Pasar el parámetro como prop
  },
  {
    path: "/detalle-print/:uniqueId",
    name: "CaseDetailPrint",
    component: CaseDetailPrint,
    props: (route) => ({ initialUniqueId: route.params.uniqueId }), // Pasar el parámetro como prop
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const publicRoutes = [
  "ReportForm",
  "SearchFormPublic",
  "thank-you",
  "NotFound",
  "CaseDetailPublic",
  "CaseDetailPrint",
];

// Redirección basada en la autenticación
router.beforeEach((to, from, next) => {
  const authToken = localStorage.getItem("authToken");

  if (publicRoutes.includes(to.name)) {
    next(); // Permite el acceso a rutas públicas
  } else if (to.name !== "Login" && !authToken) {
    // Si no hay token y no está intentando acceder a la página de login, redirige a login
    next({ name: "Login" });
  } else if (to.name === "Login" && authToken) {
    // Si hay un token y está intentando acceder a login, redirige a home
    next({ name: "Home" });
  } else {
    // Si ninguna de las condiciones anteriores se cumple, permite la navegación
    next();
  }
});

export default router;
