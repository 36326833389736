// src/store.js
import { createStore } from "vuex";

export default createStore({
  state: {
    searchParams: {},
  },
  mutations: {
    setSearchParams(state, params) {
      state.searchParams = params;
    },
  },
  actions: {
    updateSearchParams({ commit }, params) {
      commit("setSearchParams", params);
    },
  },
  getters: {
    getSearchParams: (state) => state.searchParams,
  },
});
