<template>
  <v-app>
    <v-app-bar app>
      <v-img src="https://cmcordillera.cl/wp-content/uploads/2021/08/logo-cm-cordillera.png" alt="Left Image"
        class="toolbar-image left-image" />
      <v-spacer></v-spacer>
      <v-img src="https://industriales.cl/intranet/wp-content/uploads/avatars/348/1691608329-bpfull.jpg"
        alt="Right Image" class="toolbar-image right-image" />
    </v-app-bar>

    <v-navigation-drawer app>
      <v-list>
        <v-list-item link :to="{ name: 'Home' }">
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'ReportForm' }">
          <v-list-item-title>Denunciar</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'SearchFormPublic' }">
          <v-list-item-title>Buscar Publica</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'SearchForm' }">
          <v-list-item-title>Buscar Denuncias</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="isAuthenticated" @click="logout">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  computed: {
    isAuthenticated() {
      return !!localStorage.getItem('authToken');
    }
  },
  methods: {
    logout() {
      localStorage.removeItem('authToken');
      this.$router.push({ name: 'Login' });
    }
  }
};
</script>

<style>
@import '~vuetify/styles';
.toolbar-image {
  height: 50px;
  /* Ajusta el tamaño según tus necesidades */
}

.left-image {
  margin-left: 10px;
}

.right-image {
  margin-right: 10px;
}
</style>
