<template>
    <v-container>
        <v-card class="mx-auto my-12 pa-6" max-width="600">
            <v-card-title class="text-center text-h5">¡Gracias!</v-card-title>
            <v-card-text>
                Su denuncia ha sido ingresada correctamente. Nos pondremos en contacto con usted si es necesario.
                <br><br>
                <span v-if="uniqueId">Su identificador único es: <strong>{{ uniqueId }}</strong></span>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="primary" @click="$router.push('/')">Volver al inicio</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "ThankYou",
    computed: {
        uniqueId() {
            return this.$route.query.uniqueId;
        }
    }
};
</script>
    