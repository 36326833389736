<template>
    <div ref="headerToPrint" id="headerToPrint">
        <v-container>
            <v-card>
                <v-card-title>
                    <v-row>
                        <v-col>
                            <img src="@/assets/Logo1.png" alt="Logo" class="logo-image" />
                        </v-col>
                        <v-col class="text-right">
                            <img src="@/assets/Logo2.jpg" alt="Logo" class="logo-image" />
                        </v-col>
                    </v-row>
                </v-card-title>
            </v-card>
        </v-container>
    </div>

    <div ref="contentToPrint" id="contentToPrint">
        <v-container>
            <v-card>
                <v-card-title>INFORMACIÓN GENERAL DEL DENUNCIANTE</v-card-title>
                <v-list>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Empresa:</v-list-item-title>
                            <v-list-item-subtitle>{{ caseDetails.company }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="caseDetails.compliant_type_id !== 2">
                        <v-list-item-content>
                            <v-list-item-title>Nombre:</v-list-item-title>
                            <v-list-item-subtitle>{{ caseDetails.name }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="caseDetails.email !== 'no@email.com'">
                        <v-list-item-content>
                            <v-list-item-title>Correo:</v-list-item-title>
                            <v-list-item-subtitle>{{ caseDetails.email }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Fecha Acontecimiento:</v-list-item-title>
                            <v-list-item-subtitle>{{ formatDate(caseDetails.date) }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Fecha Ingreso:</v-list-item-title>
                            <v-list-item-subtitle>{{ formatDate(caseDetails.register_date)
                                }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Tipo de Denuncia:</v-list-item-title>
                            <v-list-item-subtitle>{{ caseDetails.type }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>

            <v-divider class="my-2"></v-divider>

            <v-card>
                <v-card-title>DETALLE</v-card-title>
                <v-card-text>{{ caseDetails.description }}</v-card-text>
            </v-card>

            <v-divider class="my-2"></v-divider>

            <v-card>
                <v-card-title>DETALLE DE LA RESOLUCIÓN DEL CASO</v-card-title>
                <v-card-text>{{ caseDetails.resolutor_detail }}</v-card-text>
            </v-card>

            <v-divider class="my-2"></v-divider>

            <!-- <v-card-title class="text-h6">Detalle de Denuncia</v-card-title> -->
            <v-card-subtitle>FECHA RESOLUCIÓN: {{ formatDate(caseDetails.resolutor_date) }}</v-card-subtitle>
            <v-card-subtitle>Número de Identificación: {{ caseDetails.unique_id }}</v-card-subtitle>

        </v-container>
    </div>

    <v-container>
        <v-card>
            <v-card-actions>
                <v-row justify="center">
                    <v-btn @click="goBack">Volver</v-btn>
                    <v-btn @click="generatePDF(this.initialUniqueId)">Generar PDF</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-container>

</template>

<script>
import axiosInstance from '../axios';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";


export default {
    name: 'CaseDetailPrint',
    props: {
        initialUniqueId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            caseDetails: {
                caseNumber: '',
                email: '',
                unique_id: this.initialUniqueId,
                date: '',
                type: '',
                status: '',
                description: '',
                compliant_type_id: '',
                name: '',
                register_date: '',
                company: '',
                resolutor_detail: '',
                resolutor_date: ''
            },
            step: 0, // Inicializar con el primer estado, ajustar según sea necesario
        };
    },
    created() {
        const caseId = this.initialUniqueId;
        console.log('Identificador único:', caseId);
        this.getCaseDetails(caseId);
    },
    methods: {
        formatDate(date) {
            const dateObj = new Date(date);
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            return `${day}-${month}-${year}`;
        },
        async getCaseDetails(caseId) {
            let binnacleResponse;
            let id, name, email, date, compliant_type_desc, status_desc, detail, status_id, unique_id, register_date, company_desc, resolutor_detail, compliant_type_id;

            try {

                const responseDetail = await axiosInstance.get(`/compliant-user.php?unique_id=${caseId}`);
                console.log('Detalles del caso:', responseDetail.data);
                ({ id, name, email, date, compliant_type_desc, status_desc, detail, status_id, unique_id, register_date, company_desc, resolutor_detail, compliant_type_id } = responseDetail.data);

                const responseBinnacle = await axiosInstance.get(`/binnacle.php?compliant_id=${id}`);

                const mostRecent = responseBinnacle.data.reduce((latest, current) => {
                    return new Date(current.change_date) > new Date(latest.change_date) ? current : latest;
                });

                console.log('Bitácora data:', responseBinnacle);

                console.log('Bitácora search:', mostRecent);

                binnacleResponse = mostRecent.change_date
            } catch (error) {
                console.error('Error al obtener caso:', error);
            }

            this.caseDetails = {
                caseNumber: caseId,
                email: email,
                date: date,
                unique_id: unique_id,
                type: compliant_type_desc,
                status: status_desc,
                description: detail,
                name: name,
                register_date: register_date,
                company: company_desc,
                compliant_type_id: compliant_type_id,
                resolutor_detail: resolutor_detail,
                resolutor_date: binnacleResponse
            };

            console.log('Detalles del caso:', this.caseDetails);

            this.step = status_id;
            this.step--; // Ajustar el índice del stepper
        },
        goBack() {
            this.$router.push({ name: 'SearchFormPublic' });
        },
        async generatePDF(caseID) {
            const content = this.$refs.contentToPrint;
            const header = this.$refs.headerToPrint;

            if (!(content instanceof HTMLElement) || !(header instanceof HTMLElement)) {
                console.error('El contenido o el encabezado no son nodos DOM válidos.');
                return;
            }

            try {
                // Crear el PDF
                const pdf = new jsPDF({
                    orientation: 'portrait',
                    unit: 'mm',
                    format: 'letter'
                });

                const pageWidth = pdf.internal.pageSize.getWidth();
                const pageHeight = pdf.internal.pageSize.getHeight();
                const margin = 10;
                const contentWidth = pageWidth - (2 * margin);

                // Generar canvas del encabezado
                const headerCanvas = await html2canvas(header);
                const headerData = headerCanvas.toDataURL('image/png');
                const headerAspectRatio = headerCanvas.width / headerCanvas.height;
                const headerHeight = contentWidth / headerAspectRatio;

                // Generar canvas del contenido
                const contentCanvas = await html2canvas(content);
                const contentData = contentCanvas.toDataURL('image/png');
                const contentAspectRatio = contentCanvas.width / contentCanvas.height;
                const contentImgHeight = contentWidth / contentAspectRatio;

                let heightLeft = contentImgHeight;
                let position = margin + headerHeight;
                let page = 1;

                const addPageContent = () => {
                    // Agregar encabezado
                    pdf.addImage(headerData, 'PNG', margin, margin, contentWidth, headerHeight);

                    // Agregar contenido
                    pdf.addImage(
                        contentData,
                        'PNG',
                        margin,
                        position,
                        contentWidth,
                        contentImgHeight,
                        '',
                        'FAST'
                    );

                    // Agregar número de página
                    pdf.setFontSize(10);
                    pdf.text(`Página ${page}`, pageWidth / 2, pageHeight - 5, { align: 'center' });
                };

                addPageContent();
                heightLeft -= (pageHeight - position - margin);

                while (heightLeft > 0) {
                    position = heightLeft - contentImgHeight;
                    pdf.addPage();
                    page++;
                    addPageContent();
                    heightLeft -= (pageHeight - margin - headerHeight - margin);
                }

                pdf.save(`Reporte_caso_${caseID}.pdf`);
            } catch (error) {
                console.error('Error al generar el PDF:', error);
            }
        }

    },
};
</script>

<style scoped>
.v-card {
    background-color: #ffffff;
    /* Color de fondo claro para el card */
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
    /* Sombra suave */
}

.v-card-title {
    color: #1976D2;
    /* Color primario de Vuetify */
}

.v-list-item-title {
    font-weight: bold;
}

.v-list-item-subtitle {
    font-size: 1rem;
}

.v-divider {
    border-top: 2px solid #1976D2;
    /* Color de la línea de división */
}

.v-btn {
    font-weight: bold;
}

.logo-image {
    width: auto;
    height: auto;
    object-fit: contain;
    /* Mantiene la proporción de la imagen dentro de los límites */
}
</style>