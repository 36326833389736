<template>
    <v-data-table :headers="headers" :items="cases" item-key="id" :items-per-page="itemsPerPage" hide-default-footer>
        <template v-slot:item="{ item }">
            <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.unique_id }}</td>
                <td>{{ item.register_date }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.compliant_type_desc }}</td>
                <td>{{ item.status_desc }}</td>
                <td>
                    <v-btn @click="viewDetails(item.id)" color="primary" small>
                        Detalles
                    </v-btn>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
import { useRouter } from 'vue-router';

export default {
    name: 'CaseTable',
    props: {
        cases: {
            type: Array,
            required: true
        },
        itemsPerPage: {
            type: Number,
            default: 5 // Valor por defecto
        }
    },
    setup(props) {
        const router = useRouter();

        console.log(props.cases);

        const headers = [
            { title: 'Número de Caso', value: 'id' },
            { title: 'Identificador', value: 'unique_id' },
            { title: 'Fecha Ingreso', value: 'date' },
            { title: 'Fecha denuncia', value: 'date' },
            { title: 'Tipo', value: 'compliant_type_desc' },
            { title: 'Estado', value: 'status_desc' },
            { title: 'Acción', value: 'action', sortable: false },
        ];

        function viewDetails(caseId) {
            router.push({ name: 'CaseDetail', params: { id: caseId } });
        }

        return {
            headers,
            viewDetails
        };
    }
};
</script>
