<template>
    <v-container>
        <v-card class="mx-auto my-12 pa-6" max-width="600">
            <v-card-title class="text-h5">Formulario de Denuncia</v-card-title>
            <v-card-text>
                <v-form>
                    <v-select label="Tipo de Denuncia" variant="outlined" v-model="form.type" :items="types"
                        item-value="value" item-title="text" />
                    <v-select label="Empresa" v-model="form.company" variant="outlined" :items="companies"
                        item-value="value" item-title="text" />
                    <v-text-field label="Rut" v-model="form.rut" variant="outlined" required type="text"
                        v-if="form.type === '1'"></v-text-field>
                    <v-text-field label="Nombre" v-model="form.name" variant="outlined" required type="text"
                        v-if="form.type === '1'"></v-text-field>
                    <v-text-field label="Correo" v-model="form.email" variant="outlined" type="email"></v-text-field>
                    <v-text-field label=" Fecha acontecimiento" v-model="form.date" variant="outlined" required
                        type="date" :min-date="minDate" :max-date="maxDate"></v-text-field>
                    <v-textarea label="Descripción" v-model="form.description" rows="" variant="outlined"
                        required></v-textarea>
                </v-form>
            </v-card-text>
            <v-card-actions justify="center">
                <v-btn color="primary" @click="submitForm">Enviar</v-btn>
                <v-btn color="secondary" @click="resetForm">Limpiar</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import axiosInstance from '../axios';

export default {
    name: "ReportForm",
    data() {
        return {
            form: {
                email: "",
                date: this.getCurrentDate(),
                description: "",
                type: "1",
                company: "1",
                rut: "",
                name: "",
            },
            types: [],
            companies: [],
            minDate: this.getMinDate(),
            maxDate: this.getCurrentDate(),
        };
    },
    methods: {
        async fetchTypesAndCompanies() {
            try {
                const [typesResponse, companiesResponse] = await Promise.all([
                    axiosInstance.get('/compliant-type.php'), // Reemplaza con la URL correcta para tipos
                    axiosInstance.get('/company.php') // Reemplaza con la URL correcta para empresas
                ]);

                console.log("Respuesta de tipos:", typesResponse.data);
                console.log("Respuesta de empresas:", companiesResponse.data);

                // Mapear los tipos
                if (Array.isArray(typesResponse.data)) {
                    this.types = typesResponse.data.map(item => ({
                        value: item.id,
                        text: item.description
                    }));
                } else {
                    console.error('Error: typesResponse.data no es un array', typesResponse.data);
                }

                // Mapear las empresas
                if (Array.isArray(companiesResponse.data)) {
                    this.companies = companiesResponse.data.map(item => ({
                        value: item.id,
                        text: item.description
                    }));
                } else {
                    console.error('Error: companiesResponse.data no es un array', companiesResponse.data);
                }

            } catch (error) {
                console.error("Error fetching types and companies:", error);
            }
        },
        async submitForm() {
            if (this.form.type === '2') {
                this.form.rut = "";
                this.form.name = "";
            }

            if (this.form.type === '2' && this.form.email === "") {
                this.form.email = "no@email.com";
            }

            try {
                const response = await axiosInstance.post(
                    '/compliant-user.php',
                    new URLSearchParams({
                        status_id: 1,
                        email: this.form.email,
                        detail: this.form.description,
                        compliant_type_id: this.form.type,
                        date: this.form.date,
                        identification_number: this.form.rut,
                        company_id: this.form.company,
                        name: this.form.name,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    }
                );

                const uniqueId = response.data.unique_id;

                console.log("Respuesta del servidor:", response.data);
                console.log("Identificador único:", uniqueId);
                this.$router.push({
                    name: 'thank-you',
                    query: { uniqueId: uniqueId }
                });

            } catch (error) {
                console.error("Error al enviar el formulario:", error);
            }

            this.resetForm();
        },
        resetForm() {
            this.form = {
                name: "",
                email: "",
                date: this.getCurrentDate(),
                description: "",
                type: "1",
                company: "1",
                rut: "",
            };
        },
        getCurrentDate() {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        getMinDate() {
            const year = new Date().getFullYear();
            return `01-08-${year}`; // 1 de agosto del año actual
        },
    },
    async mounted() {
        await this.fetchTypesAndCompanies();
    }
};
</script>


