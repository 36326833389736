<template>
    <v-container>
        <v-card class="mx-auto my-12 pa-6" max-width="800">
            <v-card-title class="text-h4">Detalle de Denuncia</v-card-title>
            <v-row>
                <v-col cols="6">
                    <v-card-subtitle>Número de Caso: {{ caseDetails.caseNumber }}</v-card-subtitle>
                </v-col>
                <v-col cols="6">
                    <v-card-subtitle>Número de Identificación: {{ caseDetails.unique_id }}</v-card-subtitle>
                </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row class="mt-4">
                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-title>Información General</v-card-title>
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Empresa:</v-list-item-title>
                                    <v-list-item-subtitle>{{ caseDetails.company }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="caseDetails.compliant_type_id !== 2">
                                <v-list-item-content>
                                    <v-list-item-title>Nombre:</v-list-item-title>
                                    <v-list-item-subtitle>{{ caseDetails.name }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="caseDetails.email !== 'no@email.com'">
                                <v-list-item-content>
                                    <v-list-item-title>Correo:</v-list-item-title>
                                    <v-list-item-subtitle>{{ caseDetails.email }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Fecha Acontecimiento:</v-list-item-title>
                                    <v-list-item-subtitle>{{ formatDate(caseDetails.date) }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Fecha Ingreso:</v-list-item-title>
                                    <v-list-item-subtitle>{{ formatDate(caseDetails.register_date)
                                        }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Tipo de Denuncia:</v-list-item-title>
                                    <v-list-item-subtitle>{{ caseDetails.type }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Estado:</v-list-item-title>
                                    <v-list-item-subtitle>{{ caseDetails.status }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>

                <v-col cols="12" md="6">
                    <v-card>
                        <v-card-title>Detalle</v-card-title>
                        <v-card-text>{{ caseDetails.description }}</v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>

            <!-- Stepper -->
            <v-stepper v-model="step" alt-labels>
                <v-stepper-header>
                    <v-stepper-item title="Creado" value="1"></v-stepper-item>

                    <v-divider></v-divider>

                    <v-stepper-item title="Aceptado" value="2"></v-stepper-item>

                    <v-divider></v-divider>

                    <v-stepper-item title="En Revisión" value="3"></v-stepper-item>

                    <v-divider></v-divider>

                    <v-stepper-item title="Resolución" value="4"></v-stepper-item>

                    <v-divider></v-divider>

                    <v-stepper-item title="Finalizada" value="5"></v-stepper-item>
                </v-stepper-header>

            </v-stepper>

            <div v-if="caseDetails.statusId < 5">

                <v-divider class="my-4"></v-divider>
                <v-row>
                    <v-col cols="12" md="8">
                        <v-select v-model="selectedStatus" :items="availableStatuses" variant="outlined"
                            item-title="text" item-value="value" label="Seleccionar nuevo estado"></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-btn color="primary" @click="updateStatus">Actualizar Estado</v-btn>
                    </v-col>
                </v-row>

            </div>
            <v-divider class="my-4"></v-divider>

            <v-row class="mt-4">
                <v-col cols="12">
                    <v-card>
                        <v-card-title>Detalle</v-card-title>
                        <v-card-text>
                            <v-textarea variant="outlined" v-model="caseDetails.resolutor_detail" rows="10"
                                outlined></v-textarea>
                            <v-row justify="center" v-if="caseDetails.statusId < 5">
                                <v-btn color="primary" @click="updateResolverDetail">Guardar</v-btn>
                            </v-row>
                        </v-card-text>

                    </v-card>
                </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>

            <!-- Tabla de Acciones -->
            <v-card>
                <v-card-title>Bitácora</v-card-title>
                <v-data-table :headers="actionsHeaders" :items="caseDetails.actions" item-key="id" class="elevation-1"
                    hide-default-footer=true items-per-page="-1">
                </v-data-table>
            </v-card>

            <v-divider class="my-4"></v-divider>

            <v-row justify="center">
                <v-btn @click="goBack" color="primary" class="ma-2">Volver</v-btn>
                <v-btn @click="downloadFile" color="primary" class="ma-2"
                    v-if="caseDetails.statusId >= 5">Reporte</v-btn>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import axiosInstance from '../axios';

export default {
    name: 'CaseDetail',
    data() {
        return {
            caseDetails: {
                compliant_type_id: '',
                caseNumber: '',
                email: '',
                unique_id: '',
                company: '',
                date: '',
                register_date: '',
                type: '',
                status: '',
                statusId: '1',
                description: '',
                resolutor_detail: '',
                actions: [] // Aquí se almacenarán las acciones
            },
            step: 0, // Inicializar con el primer estado, ajustar según sea necesario
            selectedStatus: null, // Estado seleccionado para actualización
            availableStatuses: [
                { value: '2', text: 'Aceptada' },
                { value: '3', text: 'En Revisión' },
                { value: '4', text: 'Resolución' },
                { value: '5', text: 'Finalizada' },
                { value: '6', text: 'Rechazada' }
            ],
            allStatuses: [
                { value: '1', text: 'Creada' },
                { value: '2', text: 'Aceptada' },
                { value: '3', text: 'En Revisión' },
                { value: '4', text: 'Resolución' },
                { value: '5', text: 'Finalizada' },
                { value: '6', text: 'Rechazada' }
            ],
            actionsHeaders: [
                { title: 'Fecha', value: 'date' },
                { title: 'Usuario', value: 'fullname' },
                { title: 'Comentario', value: 'comment' }
            ]
        };
    },
    created() {
        const caseId = this.$route.params.id;
        this.getCaseDetails(caseId);
    },
    methods: {
        formatDate(date) {
            const dateObj = new Date(date);
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            return `${day}-${month}-${year}`;
        },
        extractDetails(data) {
            console.log('Datos de la bitácora:', data);
            return data.map((item, index) => {
                const { change_date, fullname, detail } = item;
                return { id: index + 1, date: this.formatDate(change_date), fullname: fullname, comment: detail };
            });
        },
        async updateResolverDetail() {
            try {


                console.log('Detalle:', this.caseDetails.resolutor_detail);

                await axiosInstance.put(
                    '/compliant-detail.php',
                    new URLSearchParams({
                        id: this.caseDetails.caseNumber,
                        resolutor_detail: this.caseDetails.resolutor_detail
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    }
                );

                console.log('Detalle agregado:');

            } catch (error) {
                console.error('Error al obtener bitacora:', error);
            }
        },
        async getCaseDetails(caseId) {

            let binnacleResponse;
            let name, email, date, compliant_type_desc, status_desc, detail, status_id, unique_id, register_date, company_desc, resolutor_detail, compliant_type_id;

            try {

                const responseDetail = await axiosInstance.get(`/compliant-resolutor.php?compliant_id=${caseId}`);
                console.log('Detalles del caso:', responseDetail.data);
                ({ name, email, date, compliant_type_desc, status_desc, detail, status_id, unique_id, register_date, company_desc, resolutor_detail, compliant_type_id } = responseDetail.data);

            } catch (error) {
                console.error('Error al obtener caso:', error);
            }

            try {
                const response = await axiosInstance.get(`/binnacle.php?compliant_id=${caseId}`);
                console.log('Respuesta de la bitácora:', response.data);
                binnacleResponse = this.extractDetails(response.data);
                console.log('Detalles del caso:', binnacleResponse);
            } catch (error) {
                console.error('Error al obtener los detalles del caso:', error);
            }
            this.caseDetails = {
                caseNumber: caseId,
                email: email,
                date: date,
                company: company_desc,
                register_date: register_date,
                unique_id: unique_id,
                type: compliant_type_desc,
                status: status_desc,
                description: detail,
                actions: binnacleResponse,
                resolutor_detail: resolutor_detail,
                compliant_type_id: compliant_type_id,
                name: name
            };

            this.step = status_id;
            this.caseDetails.statusId = status_id;
            this.step--; // Ajustar el índice del stepper
            await this.filterAvailableStatuses();
        },
        async filterAvailableStatuses() {

            const currentStatusIndex = this.allStatuses.findIndex(status => status.value === this.caseDetails.statusId.toString());
            console.log('details:', this.step);
            console.log('Índice de estado actual:', currentStatusIndex);
            this.availableStatuses = this.allStatuses.slice(currentStatusIndex + 1);
        },
        async updateStatus() {
            if (!this.selectedStatus) return;

            try {
                const response = await axiosInstance.put(
                    '/compliant-resolutor.php',
                    new URLSearchParams({
                        id: this.caseDetails.caseNumber,
                        status_id: this.selectedStatus,
                        user_id: localStorage.getItem('user_id')
                    }),
                    {
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                    }
                );
                console.log('Estado actualizado:', response.data);

                // Actualiza el estado del caso después de la respuesta del servidor
                const newStatus = this.allStatuses.find(status => status.value === this.selectedStatus).text;
                this.caseDetails.status = newStatus;
                this.filterAvailableStatuses(); // Actualizar estados disponibles

                // Agrega un nuevo registro en la bitácora
                this.caseDetails.actions.push({
                    id: this.caseDetails.actions.length + 1,
                    date: new Date().toISOString().split('T')[0],
                    fullname: localStorage.getItem('username'),
                    comment: `Estado cambiado a ${newStatus}`
                });

                try {

                    await axiosInstance.post(
                        '/binnacle.php',
                        new URLSearchParams({
                            compliant_id: this.caseDetails.caseNumber,
                            user_id: localStorage.getItem('user_id'),
                            detail: `Estado cambiado a ${newStatus}`
                        }),
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                        }
                    );

                    console.log('Detalle agregado:');

                } catch (error) {
                    console.error('Error al agregar detalle:', error);

                }

                // Restablecer la selección
                this.step = this.selectedStatus;
                this.selectedStatus = null;
            } catch (error) {
                console.error('Error al actualizar estado:', error);
            }
        },
        goBack() {
            const searchParams = this.$store.getters.getSearchParams;
            this.$router.push({ name: 'SearchForm', query: searchParams });
        },
        downloadFile() {
            console.log('Descargar archivo');
            this.$router.push({ name: 'CaseDetailPrint', params: { uniqueId: this.caseDetails.unique_id } });
        }
    },
};
</script>

<style scoped>
.v-card {
    background-color: #f9f9f9;
    /* Color de fondo claro para el card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Sombra suave */
}

.v-card-title {
    color: #1976D2;
    /* Color primario de Vuetify */
}

.v-list-item-title {
    font-weight: bold;
}

.v-list-item-subtitle {
    font-size: 1rem;
}

.v-divider {
    border-top: 2px solid #1976D2;
    /* Color de la línea de división */
}

.v-btn {
    font-weight: bold;
}

.v-stepper {
    margin-top: 20px;
}

.v-stepper-step {
    font-weight: bold;
}

.v-stepper-content {
    padding: 16px;
}
</style>