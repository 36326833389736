<template>
    <v-container>
        <v-card class="mx-auto my-12 pa-6" max-width="600">
            <v-card-title class="text-h5">Acceso no autorizado</v-card-title>
            <v-card-text>
                No tiene permiso para acceder a esta página. Por favor, inicie sesión con una cuenta autorizada o
                contacte al administrador.
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="primary" @click="$router.push('/login')">Iniciar sesión</v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "UnauthorizedAccess",
};
</script>

<style scoped>
.v-card-title {
    font-weight: bold;
}
</style>