import axios from "axios";

// Configuración base para Axios
const axiosInstance = axios.create({
  baseURL: "https://compliance.frontislab.cl/api",
  // baseURL: "http://localhost:8888/compliant-api",
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor para agregar el token a las solicitudes
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor de respuesta para manejar errores globalmente
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Puedes manejar errores específicos aquí, por ejemplo, redirigir al login si el token expiró
    if (error.response && error.response.status === 401) {
      // Limpiar el token y redirigir al login si es necesario
      localStorage.removeItem("authToken");
      window.location = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
