<template>
    <v-container>
        <v-card class="mx-auto my-12 pa-6" max-width="1200">
            <v-card-title class="text-h5">Denuncias Realizadas</v-card-title>
            <v-form @submit.prevent="searchCases">
                <v-text-field label="Fecha" type="date" v-model="search.date" variant="outlined" :clearable="true"
                    :placeholder="datePlaceholder" />
                <v-select label="Tipo de Denuncia" v-model="search.type" variant="outlined" :items="types"
                    item-value="value" item-title="text" />
                <v-select label="Estado Denuncia" v-model="search.status" variant="outlined" :items="status"
                    item-value="value" item-title="text" />
                <v-row justify="center">
                    <v-btn type="submit" color="primary">Buscar</v-btn>
                </v-row> </v-form>

            <v-divider class="my-4"></v-divider>


            <!-- Mostrar la tabla solo si hay resultados -->
            <case-table v-if="cases.length > 0" :cases="cases" :items-per-page="itemsPerPage" />

            <!-- Mostrar un mensaje si no hay resultados -->
            <div v-else-if="hasSearched" class="mt-4 text-center">
                <v-alert type="info" border="left" color="info">
                    No se encontraron resultados para los valores ingresados.
                </v-alert>
            </div>

            <!-- Paginación si hay resultados -->
            <v-pagination v-if="cases.length > 0" v-model="currentPage" :length="totalPages" class="mt-4"
                next-icon="mdi-arrow-right" prev-icon="mdi-arrow-left" />
        </v-card>
    </v-container>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import CaseTable from '../components/CaseTable.vue';
import { useRouter } from 'vue-router';
import axiosInstance from '../axios';

export default {
    name: 'SearchForm',
    components: { CaseTable },
    setup() {
        const router = useRouter();
        const search = ref({
            date: '-1',
            type: '1',
            status: '-1'
        });
        const cases = ref([]);
        const totalItems = ref(0);
        const itemsPerPage = ref(5); // Valor por defecto de 5
        const currentPage = ref(1);
        const totalPages = ref(0);
        const types = ref([]);
        const status = ref([]);
        const errorMessage = ref(''); // Mensaje de error para la interfaz de usuario
        const hasSearched = ref(false); // Indica si ya se realizó una búsqueda
        const datePlaceholder = ref('Selecciona una fecha');

        // agregar una funcion para formatear la fecha
        const formatDate = (date) => {
            const dateObj = new Date(date);
            // Ajustar la fecha para manejar correctamente la zona horaria
            dateObj.setMinutes(dateObj.getMinutes() + dateObj.getTimezoneOffset());
            const year = dateObj.getFullYear();
            const month = String(dateObj.getMonth() + 1).padStart(2, '0');
            const day = String(dateObj.getDate()).padStart(2, '0');
            return `${day}-${month}-${year}`;
        };

        const fetchCases = async (page = 1) => {
            try {

                // crear parametros de búsqueda
                const params = {
                    status_id: search.value.status,
                    compliant_type_id: search.value.type,
                    page: page,
                    per_page: itemsPerPage.value,
                    // agregar la fecha a los parámetros de búsqueda solo si no es -1
                };

                // si la fecha no es -1, agregarla a los parámetros
                if (search.value.date !== '-1') {
                    params.date = formatDate(search.value.date);
                }

                console.log('search', params);

                const response = await axiosInstance.get('/compliant-resolutor.php', {
                    params: params
                });

                cases.value = response.data.data;
                totalItems.value = response.data.total;
                totalPages.value = response.data.total_pages; // Ajusta el total de páginas
                currentPage.value = response.data.page; // Ajusta la página actual
                hasSearched.value = true; // Marca que se realizó una búsqueda
                errorMessage.value = ''; // Limpiar el mensaje de error en caso de éxito

            } catch (error) {
                hasSearched.value = true; // Marca que se realizó una búsqueda
                if (error.response) {
                    const statusCode = error.response.status;

                    if (statusCode >= 500) {
                        // Error del servidor (5xx)
                        router.push({ name: 'ServerError' }); // Redirige a una página de error del servidor
                    } else if (statusCode >= 400) {
                        // Error del cliente (4xx)
                        errorMessage.value = `Error ${statusCode}: ${error.response.data.message || 'Ocurrió un error. Inténtalo de nuevo más tarde.'}`;
                    }
                } else {
                    // Errores que no son respuesta del servidor (como problemas de red)
                    errorMessage.value = 'Error de red: ' + error.message;
                }
            }
        };

        const searchCases = () => {
            fetchCases(1); // Siempre empezar desde la primera página al buscar
        };

        const fetchTypesAndStatus = async () => {
            try {
                const [typesResponse, statusResponse] = await Promise.all([
                    axiosInstance.get('/compliant-type.php'), // Reemplaza con la URL correcta
                    axiosInstance.get('/status.php') // Reemplaza con la URL correcta
                ]);

                // Mapear los tipos
                types.value = typesResponse.data.map(item => ({
                    value: item.id,
                    text: item.description // Ajustado a 'description'
                }));

                // si en local storage tenemos user_type_id = 2, solo debemos dejar el tipo 1
                const userType = localStorage.getItem('user_type_id');
                if (userType === '2') {
                    types.value = types.value.filter(item => item.value === '1');
                }

                // Mapear los estados
                status.value = statusResponse.data.map(item => ({
                    value: item.id,
                    text: item.description // Ajustado a 'description'
                }));

                // Opcional: agrega un valor predeterminado
                status.value.unshift({ value: '-1', text: 'Todos los estados' });

            } catch (error) {
                console.error('Error fetching types and status:', error);
                errorMessage.value = 'Error al cargar tipos y estados';
            }
        };

        onMounted(async () => {
            await fetchTypesAndStatus();

            // Fetch initial data if there are search params in the query
            const query = new URLSearchParams(window.location.search);
            if (query.get('date') || query.get('type') || query.get('status')) {
                search.value.date = query.get('date') || '';
                search.value.type = query.get('type') || '1';
                search.value.status = query.get('status') || '1';
                searchCases();
            }
        });

        // Watch for changes in currentPage and fetch new data
        watch(currentPage, (newPage) => {
            fetchCases(newPage);
        });

        return {
            search,
            cases,
            totalItems,
            itemsPerPage,
            currentPage,
            totalPages,
            types,
            status,
            searchCases,
            errorMessage,
            hasSearched,
            datePlaceholder
        };
    }
};
</script>
