<template>
    <v-container>
        <v-card class="mx-auto my-12 pa-6" max-width="600">
            <v-card-title class="text-h4">
                Bienvenido al Canal de Denuncias
            </v-card-title>
            <v-card-text>
                Canal de denuncias <strong>CMC-TMP</strong>
                Bienvenidos al canal de denuncias de Minera Cordillera y Terminal Maritimo Patache.

                <v-divider class="my-4"></v-divider>

                Para mas información, visite los siguientes protocolos.
                <v-list>
                    <v-list-item link href="/src/assets/CMC_Ley_Karin_Protocolo_Prevencion_Minera_Cordillera.pdf">
                        <v-list-item-title>Protocolo CMC</v-list-item-title>
                    </v-list-item>
                    <v-list-item link
                        href="/src/assets/assets/Ley_Karin_Protocolo_Prevencion_Terminal_Maritimo_Patache.pdf">
                        <v-list-item-title>Protocolo TMP</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
                <v-btn color="primary" @click="$router.push('/denunciar')">
                    Realizar una denuncia
                </v-btn>
                <v-btn color="secondary" @click="$router.push('/buscar')">
                    Buscar denuncias
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
export default {
    name: "HomeView",
};
</script>
