// src/plugins/vuetify.js
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { aliases } from "vuetify/iconsets/mdi";
import "@mdi/font/css/materialdesignicons.css";
import DayJsAdapter from "@date-io/dayjs";
// Ensure you are using css-loader

export default createVuetify({
  components,
  directives,
  date: {
    adapter: DayJsAdapter,
  },
  icons: {
    defaultSet: "mdi",
    aliases,
  },
});
